<template>
  <div class="solution-elements__wrapper">
    <div>
      <h3 class="text-uppercase solution-elements__header">
        Solution Elements Setup
      </h3>
      <span class="solution-elements__sub-header-info">
        Check below the components that will have the solution elements analysed in the results
      </span>
    </div>
    <div>
      <div class="solution-elements__list-item-header-wrapper">
        <h6
          class="solution-elements__list-item-header"
          tabindex="0"
          role="button"
          :aria-expanded="seeLeTypes"
          @click="seeLeTypes = !seeLeTypes"
        >
          LE Types included
        </h6>
        <mi-btn
          class="configuration-details-card__edit-btn"
          :class="seeLeTypes ? 'collapsed-icon' : ''"
          icon-type
          icon-size="14px"
          dense
          fab
          flat
          @click="seeLeTypes = !seeLeTypes"
        >
          <img src="@/assets/images/arrow_up.svg" alt="arrow up icon" />
        </mi-btn>
      </div>
      <mi-list
        v-if="seeLeTypes"
      >
        <mi-list-item
          v-for="item in staticData"
          :key="item"
          class="solution-elements__list-item"
        >
          <mi-list-item-section>
            <mi-list-item-label class="solution-elements__included-types">
              <img src="@/assets/images/check.svg" alt="checked" width="16" height="16" />
              {{ item }}
            </mi-list-item-label>
          </mi-list-item-section>
        </mi-list-item>
      </mi-list>
    </div>
  </div>
</template>

<script>
  import MiList from '@/packages/@mi-library/MiList/MiList.vue'
  import MiBtn from '@/packages/@mi-library/MiBtn/MiBtn.vue'

  export default {
    name: 'InformationSection',
    components: { MiList, MiBtn },
    data: () => ({
      staticData: ['Empty', 'Standard-LE', 'Supplier Part'],
      seeLeTypes: false
    })
  }
</script>

<style lang="scss" scoped>
  .solution-elements {
    &__wrapper {
      margin: 16px 0;
    }

    &__header {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      margin-bottom: 8px;
    }

    &__sub-header-info {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      color: #5b7085;
    }

    &__included-types {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      color: #2d6300;
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &__list-item-header-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      height: 16px;
      margin: 16px 0;
    }

    &__list-item-header {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
    }

    &__list-item {
      padding: 0;
      min-height: 21px;
    }
  }

  .collapsed-icon {
    transform: rotateZ(180deg);
  }
</style>
