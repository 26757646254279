<template>
  <mi-card class="search-summary">
    <!-- Title -->
    <mi-card-section class="search-summary__title selected-elements-padding">
      <h6 class="q-mt-none q-mb-none text-uppercase"> Selected Elements </h6>
    </mi-card-section>

    <mi-form ref="summaryForm" @submit="showCombinationsAsync()" @keydown.enter.prevent>
      <!-- Planning period range -->
      <mi-card-section class="selected-elements-padding">
        <span class="label-form text-subtitle2 text-weight-bold text-family-condensed"> Planning Period </span>
        <planning-period-range
          v-model:from="planningPeriodRange.from"
          v-model:to="planningPeriodRange.from"
          :disable-to="true"
          class="no-wrap q-mt-md planning-period-range"
          label-from=""
          label-to=""
        ></planning-period-range>
      </mi-card-section>

      <!-- Elements -->
      <mi-card-section class="search-summary__collections selected-elements-padding column no-wrap">
        <span class="label-form text-subtitle2 text-weight-bold text-family-condensed"> Structure Elements </span>

        <!-- Yellow collection -->
        <search-summary-collection
          :color="ELEMENT_COLORS.YELLOW"
          :items="selectedOptions"
        >
          <template #header>
            <search-summary-total-elements-label
              label="Yellow collection"
              :total="selectedElements.options.length"
            ></search-summary-total-elements-label>
          </template>
        </search-summary-collection>

        <!-- Green collection -->
        <search-summary-collection
          :color="ELEMENT_COLORS.GREEN"
          :items="selectedComponents"
        >
          <template #header>
            <search-summary-total-elements-label
              label="Green collection"
              :total="selectedElements.components.length"
            ></search-summary-total-elements-label>
            <mi-btn
              :ripple="false"
              class="search-summary__collection-action-btn q-py-xs q-px-sm q-ml-xs"
              flat
              @click.stop="addAllOptions"
            >
              Add all options
            </mi-btn>
          </template>
          <template #footer>
            <div
              v-if="selectedElements.components.length"
              class="set-primary-le flex justify-between items-center"
            >
              <span class="text-subtitle2 text-weight-bold"> Set solution elements analysis </span>
              <mi-switch
                v-model="selectedForLE"
                color="primary"
                @click="handleSolutionElementsActivation"
              >
              </mi-switch>
            </div>
            <div
              v-if="selectedForLE && selectedElements.components.length"
              class="edit-btn-le"
            >
              <div class="flex justify-between">
                <div class="edit-btn-le__content">
                  <div v-show="isLoadingSelectedComponents" class="edit-btn-le__content--loading">
                    <mi-spinner size="20px"></mi-spinner>
                    <span> components </span>
                  </div>
                  <span v-show="!isLoadingSelectedComponents">
                    {{ countSelectedObjects() }}/{{ selectedElementsForLes.length }} components
                  </span>
                </div>
                <div
                  class="text-weight-bold cursor-pointer"
                  role="button"
                  tabindex="0"
                  @click="handleEditSolutionsElements"
                >
                  Edit
                </div>
              </div>
              <div v-show="displayWarning" class="edit-btn-le__warning">
                <div class="edit-btn-le__warning--icon">
                  <img src="@/assets/images/vm/warning-circle.svg" alt="warning-circle" width="16" height="16" />
                </div>
                <span class="edit-btn-le__warning--info">
                  LE list needs to be reviewed to the latest components addiction
                </span>
              </div>
            </div>
          </template>
        </search-summary-collection>
        <div v-if="editSolutionElements" class="component-selection-wrapper mi-drawer-content-le">
          <div class="drawer-background"></div>
          <mi-drawer
            v-model="editSolutionElements"
            bordered
            overlay
            side="right"
            width="460"
          >
            <component-selection
              @close:edit-drawer="handleEditSolutionsElements"
            ></component-selection>
          </mi-drawer>
        </div>
      </mi-card-section>

      <mi-card-section v-if="isShowResultsLaterEnabled">
        <div class="flex colum q-pr-sm">
          <div class="multi-folder-icon">
            <img
              src="@/assets/images/my_combinations_results.svg"
              alt="show me results later icon"
              width="24"
              height="24"
            />
            <div class="flex">
              <span class="text-weight-bold text-primary text-family-condensed">
                Show me the result later
              </span>
              <span class="text-weight-regular text-primary">
                Select to check it in Saved Tables
              </span>
            </div>
            <mi-switch
              v-model="showResultsLaterToggle"
              color="primary"
              class="show-results-later-switch"
              @click="handleShowResultsLaterActivation"
            >
            </mi-switch>
          </div>
          <div v-if="showResultsLaterToggle" class="col q-pt-lg save-for-later-input">
            <mi-text-field
              v-model="jobInputName"
              :rules="[maxLength(80)]"
              placeholder="Give this job a name"
              outlined
            ></mi-text-field>
          </div>
        </div>
      </mi-card-section>

      <!-- Actions -->
      <mi-card-actions class="no-wrap combination-submit-btn">
        <mi-btn
          class="text-no-wrap"
          :class="{ 'bg-accent-disabled' : areSelectedElementsEmpty }"
          color="accent"
          type="submit"
          :disable="areSelectedElementsEmpty"
        >
          <man-spinner
            v-if="areCombinationsLoading && showResultsLaterToggle"
            variant="accent"
            size="s"
          ></man-spinner>
          <span v-else>Show combinations</span>
        </mi-btn>
      </mi-card-actions>
    </mi-form>
    <div>
      <mi-overlay :model-value="areCombinationsLoading && !showResultsLaterToggle"></mi-overlay>
      <mi-notify :model-value="areCombinationsLoading && !showResultsLaterToggle">
        <template #inner-content>
          <div>
            <p class="q-ma-none text-weight-bold">
              {{ skipCombination ? 'Saving table.' : 'Table in progress.' }}
            </p>
            <mi-loader></mi-loader>
            <button v-if="!skipCombination" class="notification__btn" @click="handleSkipWaiting">
              Skip waiting
            </button>
          </div>
        </template>
      </mi-notify>
    </div>
    <mi-inner-loading :showing="areAllOptionsLoading">
      <mi-spinner size="90px"></mi-spinner>
    </mi-inner-loading>
  </mi-card>
</template>

<script>
  import throttle from 'lodash.throttle'
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import {
    applyVariantTreeRulesAsync,
    getComponentOptions,
    postSummary,
    getAppliedVariantTreeRules,
    saveVariantTreeCombination
  } from '@/api'
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import {
    ELEMENT_COLORS,
    SEARCH_RESULT_TYPES,
    CURRENT_SELECTION_SAVE_THROTTLE_INTERVAL,
    PM_TYPE, ASYNC_REQUEST_STATUS
  } from '@/constants'
  import {
    TOGGLE_EDIT_SOLUTION_ELEMENTS_STATE,
    UPDATE_SELECTED_ELEMENTS,
    SET_ELEMENTS_LOADING_STATE
  } from '@/store/modules/search/mutationTypes'
  import { COMBINATIONS_ROUTE } from '@/router/routeNames'
  import * as analyticsConstants from '@/utils/analytics/constants'

  import PlanningPeriodRange from '@/components/base/PlanningPeriodRange.vue'
  import {
    MiCard,
    MiInnerLoading,
    MiSpinner,
    MiCardActions,
    MiCardSection
  } from '@/packages/@mi-library'
  import setRecordAnalyticsTrace, { isFlowCompletedPerSession } from '@/utils/analytics/recordAnalyticsTrace'
  import { getTimeSpent } from '@/utils/timeSpent'
  import emitFlowState from '@/utils/custom-events/customEventsEmit'
  import { poll } from '@/api/polling'
  import notify from '@/utils/notify'
  import ComponentSelection from '@/components/combinations/component-selection/ComponentSelection.vue'
  import useFeatureToggle from '@/composables/featureToggle'
  import { FEATURES } from '@/utils/featureToggle'
  import { BASIC_INFO } from '@/plugins/quasar/notifications/notificationTypes'
  import { maxLength } from '@/utils/validators'
  import SearchSummaryCollection from './SearchSummaryCollection.vue'
  import SearchSummaryTotalElementsLabel from './SearchSummaryTotalElementsLabel.vue'

  export default {
    name: 'SearchSummary',
    components: {
      PlanningPeriodRange,
      SearchSummaryCollection,
      SearchSummaryTotalElementsLabel,
      MiCard,
      MiInnerLoading,
      MiSpinner,
      MiCardActions,
      MiCardSection,
      ComponentSelection
    },
    setup() {
      const { isEnabled: isShowResultsLaterEnabled } = useFeatureToggle(
        FEATURES.VUE_APP_SHOW_RESULTS_LATER_TOGGLE
      )
      return { isShowResultsLaterEnabled }
    },
    data: () => ({
      ELEMENT_COLORS,
      correlationIdToBeSaved: null,
      skipCombination: false,
      planningPeriodRange: {
        from: '',
        to: ''
      },
      maxLength,
      jobInputName: '',
      areAllOptionsLoading: false,
      clearPoll: null,
      selectedForLE: false,
      showResultsLaterToggle: false,
      editSolutionElements: false,
      isLoadingSelectedComponents: false,
      displayWarning: false,
      productModelChangeDialog: true
    }),

    computed: {
      ...mapState(['user']),
      ...mapGetters('search', [
        'areSelectedElementsEmpty',
        'currentSelectedElementsIdsLength',
        'selectedOptions',
        'selectedComponents',
        'selectedElements',
        'selectedElementsForLes'
      ]),
      ...mapState(['user']),
      ...mapState('product-model', ['selectedSearchProductModel']),
      ...mapState('search', ['showResultsLater', 'areCombinationsLoading']),
      ...mapGetters('product-model', ['selectedProductModel', 'hasSelectedSearchProductModel'])
    },
    watch: {
      async correlationIdToBeSaved() {
        if (this.correlationIdToBeSaved) {
          await saveVariantTreeCombination({ correlationId: this.correlationIdToBeSaved })
          this.setCombinationsLoading({ areCombinationsLoading: false })
          this.tableIsInProgressNotify()
        }
      },
      currentSelectedElementsIdsLength() {
        this.selectedProductModel.encodedBusinessName && this.saveCurrentSelection()
      },
      selectedElements() {
        const allSelected = this.countSelectedObjects() === this.selectedElementsForLes.length
        this.displayWarning = !allSelected && this.selectedForLE
      },
      planningPeriodRange: {
        handler(value) {
          this.$q.localStorage.set(
            process.env.VUE_APP_STORAGE_KEY_PLANNING_PERIOD,
            { from: value.from, to: value.to }
          )
        },
        deep: true
      }
    },
    async created() {
      try {
        this.setPlanningPeriodFromLocalStorage()
        await this.getCurrentSelectionValues()
      }
      finally {
        this.SET_ELEMENTS_LOADING_STATE(false)
      }
    },
    beforeUnmount() {
      this.clearPoll?.()
      this.setResultsForLater({ showResultsLater: false })
      this.setCombinationsLoading({ areCombinationsLoading: false })
    },
    methods: {
      ...mapMutations('search', {
        UPDATE_SELECTED_ELEMENTS,
        TOGGLE_EDIT_SOLUTION_ELEMENTS_STATE,
        SET_ELEMENTS_LOADING_STATE
      }),
      ...mapActions('search', [
        'getCurrentSelection',
        'setComponentsForLE',
        'setResultsForLater',
        'setCombinationsLoading'
      ]),
      async handleSkipWaiting() {
        try {
          if (this.clearPoll) {
            this.skipCombination = true
            this.clearPoll()
            recordAnalytics(analyticsConstants.VM_SKIP_WAITING)
          }
        }
        catch (err) {
          throw new Error(
            `Error trying to save the combination into the saved tables: ${ this.correlationIdToBeSaved }`
          )
        }
      },
      countSelectedObjects() {
        return this.selectedElementsForLes
          .reduce((count, component) => count + (component.selectedForLE === true ? 1 : 0), 0)
      },
      handleEditSolutionsElements(content) {
        this.TOGGLE_EDIT_SOLUTION_ELEMENTS_STATE()
        this.editSolutionElements = !this.editSolutionElements

        if (content && content.confirmed) {
          this.isLoadingSelectedComponents = true

          setTimeout(() => {
            this.isLoadingSelectedComponents = false
            notify({
              content: 'Solution elements updated.',
              type: 'dark_info',
              position: 'bottom-right',
              progress: false,
              timeout: 2000
            }, true)
          }, 2000)
        }

        this.displayWarning = false
      },
      handleSolutionElementsActivation() {
        this.setComponentsForLE({ selectedForLE: this.selectedForLE })
      },
      handleShowResultsLaterActivation() {
        this.setResultsForLater({ showResultsLater: this.showResultsLaterToggle })
        this.jobInputName = ''

        if (this.showResultsLaterToggle) {
          recordAnalytics(analyticsConstants.VM_SAVED_FOR_LATER_CALCULATION)
        }
      },
      async addAllOptions() {
        const isFormValid = await this.$refs?.summaryForm?.validate?.()

        if (!(this.selectedComponents.length && isFormValid)) return

        this.areAllOptionsLoading = true

        try {
          const ids = this.selectedComponents.map(({ id } = {}) => id).join()

          const options = await getComponentOptions({
            ids,
            pmType: this.selectedSearchProductModel.productModelType,
            pmIdentifier: this.selectedSearchProductModel.productModelType === PM_TYPE.PRODUCT_MODEL
              ? this.selectedSearchProductModel.encodedBusinessName
              : this.selectedSearchProductModel.id,
            fromPeriod: this.planningPeriodRange.from,
            toPeriod: this.planningPeriodRange.from
          })

          this.UPDATE_SELECTED_ELEMENTS({
            elementsType: SEARCH_RESULT_TYPES.OPTIONS,
            subElementsType: SEARCH_RESULT_TYPES.CHOICES,
            elements: options
          })

          // Analytics
          recordAnalytics(analyticsConstants.VM_ADDED_ALL_OPTIONS)
        }
        finally {
          this.areAllOptionsLoading = false
        }
      },
      tableIsInProgressNotify() {
        notify({
          content: 'Table is in progress in “Saved Tables”.',
          type: BASIC_INFO,
          position: 'bottom'
        })
      },
      async showCombinationsAsync() {
        try {
          this.setCombinationsLoading({ areCombinationsLoading: true })

          const { executePoll, clearPoll } = poll()
          this.clearPoll = clearPoll

          const { status, correlationId } = await executePoll({
            correlationIdEndpoint: {
              getCorrelationId: applyVariantTreeRulesAsync,
              params: {
                options: this.selectedOptions,
                components: this.selectedComponents,
                fromPeriod: this.planningPeriodRange.from,
                toPeriod: this.planningPeriodRange.from,
                pmIdentifier:
                  this.selectedSearchProductModel.productModelType === PM_TYPE.PRODUCT_MODEL
                    ? this.selectedSearchProductModel.encodedBusinessName
                    : this.selectedSearchProductModel.id,
                pmType: this.selectedSearchProductModel.productModelType,
                showLater: this.showResultsLaterToggle,
                name: this.jobInputName
              }
            },
            asyncEndpoint: getAppliedVariantTreeRules,
            ignorePolling: this.showResultsLaterToggle
          })

          const skippedCombination = status === ASYNC_REQUEST_STATUS.STOPPED
            && this.skipCombination && !this.showResultsLaterToggle

          if (skippedCombination) this.correlationIdToBeSaved = correlationId

          const isShowResultsLaterActivated = this.showResultsLaterToggle && status === ASYNC_REQUEST_STATUS.STOPPED

          if (isShowResultsLaterActivated) {
            recordAnalytics(analyticsConstants.SHOW_ME_LATER_TOGGLE_AND_SHOW_COMBINATIONS)
            this.tableIsInProgressNotify()
            return
          }

          emitFlowState('search', 'end')

          if (status === ASYNC_REQUEST_STATUS.SUCCESS && correlationId) {
            let query = { correlationId }
            query = this.extraQueryParams(query)

            await this.$router.push({ name: COMBINATIONS_ROUTE.name, query })

            // Analytics
            setRecordAnalyticsTrace(analyticsConstants.SHOW_COMBINATIONS)
            recordAnalytics(analyticsConstants.VM_SHOW_COMBINATIONS)
            if (isFlowCompletedPerSession(analyticsConstants.VARIANT_MANAGEMENT_COMPLETED_FLOW)) {
              recordAnalytics(analyticsConstants.VM_COMPLETED_FLOW)
              recordAnalytics(
                analyticsConstants.VM_COMPLETE_FLOW_TIME_SPENT,
                { timeSpent: getTimeSpent() }
              )
            }
          }
        }
        catch (e) {
          notify({
            title: `Error ${ e.status || '' }`,
            content: 'Show combinations',
            type: 'negative'
          })
        }
        finally {
          this.setCombinationsLoading({ areCombinationsLoading: false })
          this.jobInputName = ''
          this.skipCombination = false
        }
      },
      async getCurrentSelectionValues() {
        await this.getCurrentSelection()
      },
      saveCurrentSelection: throttle(function postCurrentSummary() {
        const name = `${ this.user.username }_CurrentSelection`

        const selectedComponentsWithoutLeFlag = this.selectedComponents?.map(
          ({ selectedForLE, ...attrs }) => attrs
        )

        postSummary({
          name,
          pmEncodedBusinessName: this.selectedProductModel?.encodedBusinessName || '',
          pmType: this.selectedSearchProductModel.productModelType,
          options: this.selectedOptions,
          components: selectedComponentsWithoutLeFlag,
          currentSelection: true
        })
      }, CURRENT_SELECTION_SAVE_THROTTLE_INTERVAL),
      setPlanningPeriodFromLocalStorage() {
        const planningPeriod = this.$q.localStorage.getItem(
          process.env.VUE_APP_STORAGE_KEY_PLANNING_PERIOD
        )
        if (planningPeriod) {
          this.planningPeriodRange = {
            from: planningPeriod.from || '',
            to: planningPeriod.to || ''
          }
        }
      },
      extraQueryParams(query) {
        let queryWithParams = query
        const pp = this.getPlanningPeriod()
        const { productModelType: pmType, displayName } = this.selectedSearchProductModel
        if (pp.length) queryWithParams = { ...queryWithParams, pp }
        const productModelBusinessName = this.getProductModelBusinessName()
        if (productModelBusinessName.length) queryWithParams = { ...queryWithParams, productModelBusinessName }
        const exportName = pmType === PM_TYPE.USER_EXPORTS && displayName
        if (exportName) queryWithParams = { ...queryWithParams, exportName }
        return queryWithParams
      },
      getPlanningPeriod() {
        let pp = ''
        if ((this.planningPeriodRange.from && this.planningPeriodRange.to).length) {
          pp = `${ this.planningPeriodRange.from } - ${ this.planningPeriodRange.to }`
        }
        if ((this.planningPeriodRange.from).length) {
          pp = this.planningPeriodRange.from
        }
        return btoa(pp)
      },
      getProductModelBusinessName() {
        return btoa(this.selectedSearchProductModel.businessName ?? '')
      }

    }
  }
</script>

<style lang="scss" scoped>
  $summary-total-height: 300px;
  $collections-space-height: 60px;

  .planning-period-range {
    padding-bottom: 0;

    ::v-deep(.mi-field) {
      padding-top: 0;
    }

    ::v-deep(.mi-field.q-field .q-field__bottom) {
      padding-bottom: 0;
    }
  }

  .save-for-later-input .q-field {
    padding-bottom: 0;
  }

  .label-form {
    color: $mi-anthracite-800;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
  }

  .mi-drawer-content-le {
    .q-drawer {
      /* stylelint-disable declaration-no-important */
      top: 60px !important;
    }

    ::v-deep(.q-drawer) {
      width: 475px !important;
    }
  }

  @media (max-width: $mi-responsive-medium-width) {
    .mi-drawer-content-le {
      ::v-deep(.q-drawer) {
        width: 100vw !important;
      }
    }
  }

  .search-summary {
    background-color: $mi-silver-100;
    max-height: 100%;
    max-width: 474px;
    min-width: 370px;

    &__collections {
      max-height: calc((var(--search-summary-height) - #{$summary-total-height}));
    }

    &__collection-action-btn {
      font-size: 10px;
      text-decoration: underline;
    }
  }

  .summary-collection {
    margin-top: 8px;
    height: 50%;
    max-height: calc((var(--search-summary-height) - #{$summary-total-height} - #{$collections-space-height}) / 2);
  }

  .selected-elements-padding {
    padding: 8px 24px 0 16px;
  }

  .set-primary-le {
    padding: 6px 16px;
  }

  .edit-btn-le {
    padding: 6px 16px 8px;

    &__content {
      &--loading {
        color: $mi-silver-700;
      }
    }

    &__warning {
      display: flex;
      height: 32px;
      padding: 10px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      margin-top: 4%;
      border: 1px solid var(--color-semantic-warning-bold, $mi-yellow-900);
      background: var(--color-background-surface, $mi-white);

      &--info {
        color: var(--color-semantic-warning-bold, $mi-yellow-900);
        font-family: $mi-typography-font-family;
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
      }
    }
  }

  .component-selection-wrapper {
    z-index: 100;
  }

  .q-card__actions .q-btn {
    width: 100%;
  }

  .combination-submit-btn {
    padding: 12px 24px 24px 16px;
  }

  .multi-folder-icon {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
  }

  .notification {
    &__btn {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      color: $mi-white;
      font-size: 14px;
      font-weight: 400;
      margin-top: 16px;
      line-height: 21px;
      text-decoration: underline;
    }

    &__btn:hover {
      color: $mi-anthracite-300;
    }
  }
</style>
